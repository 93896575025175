body {
  font-family: "Poppins", sans-serif;
  background-color: rgb(31, 30, 30);
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Title {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

html {
  background-color: rgb(31, 30, 30);
}

html,
body {
  height: 100%;
}

p,
h1 {
  color: whitesmoke;
}

h1 {
  margin-bottom: 100px;
}

.UserName {
  font-size: 30px;
  margin: 10px;
}

.UserList {
  max-height: 300px;
  overflow-y: auto;
  min-height: 300px;
  margin-bottom: 15px;
}

.MatchingsList {
  margin-bottom: 15px;
  font-size: 30px;
  margin: 10px;
}

.MatchingsContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MinParticipantsHint {
  text-align: center;
  margin-top: 0px;
}

button {
  color: whitesmoke;
  background-color: slateblue;
  height: 50px;
  width: 200px;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  margin-top: 4px;
  margin-bottom: 20px;
  transition: background-color, 0.2s;
  border-radius: 10%;
}

button:hover:not(:disabled) {
  background-color: rgb(72, 63, 131);
  transform: translateY(-5px);
}

button:disabled {
  opacity: 0.6;
}

input {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-size: 30px;
  color: whitesmoke;
  background-color: rgb(0, 0, 0);
  border-color: rgb(0, 0, 0);
  border-radius: 5%;
  border: 2px solid;
  margin-bottom: 5px;
}

.InputError {
  color: red;
}

.StartScreen {
}

.UserCounter {
  color: slateblue;
}
